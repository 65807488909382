import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store/store.js'
import vuetify from './plugins/vuetify'
import { VueMasonryPlugin } from "vue-masonry";

Vue.config.productionTip = false

Vue.use(VueMasonryPlugin);


new Vue({
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
