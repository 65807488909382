import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        sectionName: "",
        users: []
    },
    getters: {
      section(state) {
        return state.sectionName
      },
      userStored(state) {
        return state.users
      }
    },
    mutations: {
        set_title (state, payload) {
            return state.sectionName = payload.value;
        },
        add_user(state, payload) {
            let index = state.users.findIndex(element => element.idUser === payload.id);
            if(index != -1) {
                state.users[index].procesado = 0;
                state.users[index].total = 0;
                return;
            } 
            
            return state.users.push({idUser: payload.id, procesado: 0, total: 0});
        },
        increment_procesado(state, payload) {
            let index = state.users.findIndex(element => element.idUser === payload.idUser);
            return state.users[index].procesado = parseInt(state.users[index].procesado) + parseInt(payload.procesado);
        },
        increment_total(state, payload) {
            let index = state.users.findIndex(element => element.idUser === payload.idUser);
            return state.users[index].total = parseInt(state.users[index].total) + parseInt(payload.total);
        }
    },
    actions: {
        set_title(context, payload) {
            context.commit('set_title ', payload);
        },
        add_user(context, payload) {
            context.commit('add_user', payload);
        },
        increment_personal(context, payload) {
            context.commit('increment_personal', payload);
        },
    }
})

export default store;