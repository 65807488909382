<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="5" class="mx-auto mt-5">
        <v-card :loading="loading">
          <v-card-title>Iniciar Session</v-card-title>
          <v-card-subtitle>Utiliza tu numero de identificaciòn y contraseña para ingresar</v-card-subtitle>
          <v-card-text>
            <!-- add validatied form -->
            <v-form ref="loginForm" v-model="valid" lazy-validation>
              <v-text-field
                v-model="credentials.Ident"
                label="Identificación"
                type="number"
                prepend-icon="mdi-account"
                :rules="rules"
                required
              ></v-text-field>
              <v-text-field
                v-model="credentials.Pw"
                label="Password"
                type="password"
                prepend-icon="mdi-lock-outline"
                :rules="rules"
                required
              ></v-text-field>
              <div class="text-center">
                <v-btn color="primary" :disabled="loading" @click="login">Ingresar</v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LoginComponent',
  data: () => ({
    loading: false,
    valid: false,
    credentials: {},
    rules: [
      v => !!v || 'Es obligaorio',
    ],
  }),
  methods: {
    login() {
      // check validation form
      if (!this.$refs.loginForm.validate()) { return; }
      this.loading = true;
      this.axios.post('/auth/login', this.credentials).then(res => {
        // save user in local storage
        localStorage.setItem('user', JSON.stringify(res.data));
        localStorage.setItem('token', res.data.Token);
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.Token;
        this.$emit('checkUser');
        this.loading = false;
      }).catch(err => {
        console.log(err)
        this.loading = false;
      });
    },
  },
  components: {
  }
}
</script>
