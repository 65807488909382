export default {
    methods: {
        GroupRelativesMixin(data) {
            var fullData = [];

            for (const key in data) {
                this.wasSaved = false;
                if(fullData.length === 0) {
                    fullData.push(data[key]);
                } else {
                    for (const el in fullData) {
                        if(fullData[el].Hestimada == data[key].Hestimada
                            && fullData[el].IdCargo == data[key].IdCargo
                                && fullData[el].IdLocacion == data[key].IdLocacion
                                && fullData[el].IdRequisiciones == data[key].IdRequisiciones) {

                            fullData[el].Cantidad = parseInt(fullData[el].Cantidad) + parseInt(data[key].Cantidad);
                            fullData[el].pedDetalles_count = parseInt(fullData[el].pedDetalles_count) + parseInt(data[key].pedDetalles_count);
                            fullData[el].tempPedDetalles_count = parseInt(fullData[el].tempPedDetalles_count) + parseInt(data[key].tempPedDetalles_count);
                            this.wasSaved = true;
                            break;
                        }
                    }

                    if(!this.wasSaved) {
                        fullData.push(data[key]);
                    }
                }
            }

            return fullData;
        }
    }
};