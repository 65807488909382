<template>
  <v-container fluid>
    <v-progress-linear indeterminate v-if="loading" />
    <v-row no-gutters>
      <v-col cols="12" md="2" v-for="user in users" :key="user.IdM_usu_sistema">
        <UserComponent :user="user" :date="dateforward" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserComponent from "./UserComponent";
import * as type from "../store/mutations/types";
import store from "../store/store";
import moment from 'moment';
export default {
  name: "DashboardComponent",
  props: ["date"],
  data: () => ({
    loading: true,
    users: [],
  }),
  mounted() {
    this.getUsers();
    setInterval(this.getUsers, 600000);
  },
  methods: {
    getUsers() {
      this.users = [];
      // check validation form
      this.loading = true;
      this.axios
        .get("/api/users?date=" + moment(this.date).add(1, 'days').format('YYYY-MM-DD'))
        .then((res) => {
          this.users = res.data;

          // saving user on redux
          res.data.forEach((element) => {
            store.commit({ type: type.ADDUSER, id: element.IdM_usu_sistema });
          });

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  components: {
    UserComponent,
  },
  computed: {
    dateforward() {
      return moment(this.date).add(1, 'days').format('YYYY-MM-DD');
    }
  },
  watch: {
    date: function () {
      // watch it
      this.getUsers();
    },
  },
};
</script>
