<template>
  <v-app style="background:rgb(57, 51, 66)">
    <v-main class="">
      <v-app-bar color="deep-purple accent-4" dark>
        <v-icon class="me-2">mdi-calendar-arrow-right</v-icon>
        <v-toolbar-title>Programación {{ SectionName }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu ref="picker" v-model="picker" :close-on-content-click="false" transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon>mdi-calendar</v-icon> {{ parseDate(date) }}
            </v-btn>
          </template>
          <v-date-picker v-model="date" no-title @input="picker = false"></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn v-if="user" color="primary" @click="logout">Cerrar Sesión</v-btn>
      </v-app-bar>
      <LoginComponent @checkUser="checkUser" v-if="!user"/>
      <DashboardComponent v-if="user" :date="date" />
    </v-main>
  </v-app>
</template>

<script>
import LoginComponent from './components/LoginComponent';
import DashboardComponent from './components/DashboardComponent';
import * as type from './store/mutations/types';
import store from './store/store';
import moment from 'moment';

export default {
  name: 'App',
  data: () => ({
    user: null,
    sectionName: "",
    date: moment().format('YYYY-MM-DD'),
    picker: false,
  }),
  mounted() {
    this.checkUser();
  },
  computed: {
    SectionName() {
      return this.$store.getters.section
    }
  },
  methods: {
    checkUser() {
      this.user = JSON.parse(localStorage.getItem('user'));
    },
    logout() {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      store.commit({type: type.SETTITLE, value: ''});
      this.checkUser();
    },
    parseDate(date) {
      return moment(date).format('MMM D YYYY');
    },
  },
  components: {
    LoginComponent,
    DashboardComponent
  },
};
</script>
<style scoped>
.colorfondo {
  background-color: rgb(57, 51, 66);
}
.heigt-bg {
  height: calc(100% - 64px);
}
</style>