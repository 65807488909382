<template>
  <div :class="classDiv + ' px-2'">
    <div class="d-flex">
      <div class="flex-grow-1">
        {{ reqDetalle.cargo.alias }}<br>
        {{ parseDate() }} - <span class="caption" v-if="reqDetalle.locacion">{{ sliceAlias() }}</span><span class="caption" v-else>Sin Locación</span>
      </div>
      <div>
        <span v-if="reqDetalle.tempPedDetalles_count > 0">{{ reqDetalle.tempPedDetalles_count }}</span> <!-- ask for this -->
        <span v-else-if="reqDetalle.pedDetalles_count > 0">{{ reqDetalle.pedDetalles_count }}</span>
        <span v-else>0</span>
        / {{ reqDetalle.Cantidad }}
      </div>
    </div>
    <v-progress-linear v-if="!complete" color="#00f8ff" :buffer-value="percent_complete" :value="percent_buffer" stream></v-progress-linear>
  </div>
</template>

<script>

import moment from 'moment'
export default {
  name: 'ReqDetalleComponent',
  props: ['reqDetalle'],
  data: () => ({
    complete: false,
    classDiv: 'reqincomp lighten-5 white--text',
    percent_complete: 0,
    percent_buffer: 0,

  }),
  mounted() {
    this.reqStatus()
  },
  methods: {
    // cut the alias to avoid new line for long alias
    sliceAlias() {
      return this.reqDetalle.locacion.alias.slice(0,10)
    },
    parseDate() {
      return moment(this.reqDetalle.Festimada+' '+this.reqDetalle.Hestimada).format('h:mm a')
    },
    reqStatus() {
      this.percent_buffer = this.reqDetalle.tempPedDetalles_count / this.reqDetalle.Cantidad * 100
      this.percent_complete = this.reqDetalle.pedDetalles_count / this.reqDetalle.Cantidad * 100
      if(this.percent_complete >= 100) {
        this.complete = true
        this.classDiv = 'fondotarjeta lighten-5 white--text'
      } else {
        if (this.$store.getters.section === ' / Enviados') {
          this.classDiv = 'fondotarjetaEnviados lighten-5 white--text'
        } 
      }
    }
  },
  components: {
  }
}
</script>
<style scoped>
  .detalle {
    border: 1px solid red;
  }
  .fondotarjetaEnviados {
    background-color: rgb(146 132 247) /* #006164;  rgb(51, 64, 136); */
  }
  .fondotarjeta {
    background-color: rgb(76, 61, 179) /* #006164;  rgb(51, 64, 136); */
  }
  .reqincomp {
    background-color: #a2178d; /*#6c1b60; */
  }
</style>