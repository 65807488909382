<template>
  <v-container fluid>
    <v-card :loading="loading" class="mx-auto">
    <v-chip class="ma-2" color="primary" outlined pill>
      {{ fullName }} &nbsp; <b>{{ procesados }} / {{ total}}</b>
    </v-chip>
      <RequisicionComponent v-for="requisicion in requisiciones" :key="requisicion.Id" :requisicion="requisicion" :date="date" :user="user.IdM_usu_sistema" />
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import RequisicionComponent from './RequisicionComponent.vue'
export default {
  name: 'UserComponent',
  props: ['user', 'date'],
  data: () => ({
    loading: true,
    requisiciones: []
  }),
  mounted() {
    this.getRequisiciones();
  },
  methods: {
    getRequisiciones() {
      this.requisiciones = [];
      // check validation form
      this.loading = true;
      this.axios.get('/api/users/'+this.user.IdM_usu_sistema+'/requisiciones?date='+this.date).then(res => {
        this.requisiciones = res.data;
        this.loading = false;
      }).catch(err => {
        console.log(err)
        this.loading = false;
      });
    },
  },
  computed: {
    fullName() {
      return this.user.nombre.split(' ')[0] + ' ' + this.user.apellidos;
    },
    ...mapState({
      total(state) {
        let index = state.users.findIndex(element => element.idUser === this.user.IdM_usu_sistema);
        return state.users[index].total;
      },
      procesados(state) {
        let index = state.users.findIndex(element => element.idUser === this.user.IdM_usu_sistema);
        return state.users[index].procesado;
      }
    })
  },
  components: {
    RequisicionComponent
  },
  watch: { 
    user: function() { // watch it
      this.getRequisiciones();
    }
  }
}
</script>
