<template>
  <div v-if="reqVisible">
    <div class="d-flex Reqbgcol darken-3 white--text">
      <div class="flex-grow-1 py-2 px-2">
        <strong>{{ requisicion.cliente.Cod }} #{{ requisicion.Id }}</strong>
      </div>
      <div class="py-2 px-2">Total</div>
    </div>
    <div class="text-center py-3" v-if="loading">
      <v-progress-circular :size="50" class="mx-auto" color="primary" indeterminate></v-progress-circular>
    </div>
    <ReqDetalleComponent v-for="reqDetalle in reqDetalles" :key="reqDetalle.Id" :reqDetalle="reqDetalle" />
  </div>
  
</template>

<script>
import ReqDetalleComponent from './ReqDetalleComponent.vue'
import GroupByRelatives from '../mixins/GroupByRelatives'
import * as type from '../store/mutations/types';
import store from '../store/store';

export default {
  name: 'RequisicionComponent',
  mixins: [GroupByRelatives],
  props: ['requisicion', 'date', 'user'],
  data: () => ({
    loading: false,
    reqDetalles: [],
    reqVisible: true,
    detalles: {
      enviados: [],
      pendientes: []  
    }
  }),
  mounted() {
    this.getDetalles();
    setTimeout(this.getEnviados, 540000); //540000
  },
  methods: {
    getEnviados() {
      store.commit({ //Cambia el nombre de la sección
        type: type.SETTITLE,
        value: ' / Enviados'
      });

      this.reqVisible = (this.detalles.pendientes.length == 0)? true : false; // para ocultar las reqs pendientes y dejar las enviadas
      this.reqDetalles = this.detalles.enviados;
    },
    getDetalles() {
      this.loading = true;

      this.axios.get('/api/requisiciones/'+this.requisicion.Id+'?date='+this.date).then(res => {
        var fullData = res.data;
        var pendientes = [];
        var enviados = [];
        let procesados = 0;
        let totales = 0;
          console.log(fullData);

        // Divide la data por estado 2 pendientes y 4 enviados
        for (const row of fullData) {
          if(row.IdRequisiciones == '25670') {
            // console.log(row);
          }
          totales = parseInt(totales) + parseInt(row.Cantidad);
          procesados = parseInt(procesados) + parseInt(row.pedDetalles_count) + parseInt(row.tempPedDetalles_count);

          if(row.Estado == "4") {
            enviados.push(row);
          }else {
            pendientes.push(row);
          }
        }

        store.commit({type: type.INCREMENTPROCESADO, idUser: this.user, procesado: procesados});
        store.commit({type: type.INCREMENTTOTAL, idUser: this.user, total: totales});
        
        // Agrupa los cargos iguales por reqs, locacion y hora
        this.detalles.pendientes = this.GroupRelativesMixin(pendientes);
        this.detalles.enviados = this.GroupRelativesMixin(enviados);

        if(this.detalles.pendientes.length == 0) {
          this.reqVisible = false;
          store.commit({type: type.SETTITLE, value: ' / Pendientes'});
        } else {          
          store.commit({type: type.SETTITLE, value: ' / Pendientes'});
        }

        this.reqDetalles = this.detalles.pendientes;
        // this.reqDetalles = res.data;
        this.loading = false;
      }).catch(err => {
        console.log(err)
        this.loading = false;
      });
    },
  },
  components: {
    ReqDetalleComponent
  }
}
</script>
<style scoped>
  .Reqbgcol {
    background-color:rgb(53, 43, 75);
  }
</style>
